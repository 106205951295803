const __request = require(`./__request/__request_contentType_json`);

// 添加或更新旅客信息 http://219.128.52.2:18765/doc.html#/haolv-biz/%E7%94%A8%E6%88%B7-%E5%B8%B8%E7%94%A8%E6%97%85%E5%AE%A2/addOrUpdateFrequentContactsUsingPOST_1
const consumer_frequent_contacts_addOrUpdate = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/frequent/contacts/addOrUpdate',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_frequent_contacts_addOrUpdate;