// 国家地区
import request from '@/lib/data-service/haolv-default/consumer_web_city_areaLinkage'
// 根据id查找联系人详情
import consumer_frequent_contacts_getList from '@/lib/data-service/haolv-default/consumer_frequent_contacts_getList'
// 添加或更新旅客信息
import consumer_frequent_contacts_addOrUpdate from '@/lib/data-service/haolv-default/consumer_frequent_contacts_addOrUpdate'

export default {
    data() {
        const validateCard = (rule, value, callback) => { // 身份证
            if (this.form.certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!value) {
                    return callback(new Error("请输入身份证号"));
                } else if (!regIdCard.test(value)) {
                    callback(new Error("请输入正确的身份证号码"));
                } else {
                    callback();
                }
            } else {
                const reg = /^[A-Za-z0-9]+$/;
                if (!value) {
                    return callback(new Error("请输入证件号码"));
                } else if (!reg.test(value)) {
                    callback(new Error("请输入正确的证件号码"));
                } else {
                    callback();
                }
            }
        };
        const checkCertificateValue = (rule, value, callback) => {
            const reg = /^[A-Za-z0-9]+$/;

        };
        return {
            id: '',
            loading: false,
            form: {
                choice: 1, // 是否存储常用旅客：0.否 1.是
                username: '', // 姓名
                englishSurnames: '', // 英文姓
                englishName: '', // 英文名
                certificateType: 1, // 证件类型
                certificateValue: '', // 证件号码
                passengerExpireDate: '', // 证件有效期至
                staffSex: 1, // 性别
                phone: '', // 手机号码
                passengerType: 1, // 乘客类型
                birthday: '', // 生日
                nationality: '', // 国籍
            },
            formRules: {
                certificateType: [{required: true, message: '请选择', trigger: 'change'}],
                certificateValue: [
                    {required: true, message: '请输入证件号码', trigger: 'blur'},
                    {validator: validateCard, trigger: 'blur' }
                ],
                username: [{required: true, message: '请输入姓名', trigger: 'blur'}],
                phone: [
                    {required: true, message: '请输入手机号码', trigger: 'blur'},
                    {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
                ],
                birthday: [{required: true, message: '请选择日期', trigger: 'change'}],
                staffSex: [{required: true, message: '请选择', trigger: 'change'}],
                nationality: [{required: true, message: '请选择', trigger: 'change'}],
                passengerType: [{required: true, message: '请选择', trigger: 'change'}],
                englishSurnames: [{required: true, message: '请输入英文姓', trigger: 'blur'}],
                englishName: [{required: true, message: '请输入英文名', trigger: 'blur'}],
                passengerExpireDate: [{required: true, message: '请输入证件有效期', trigger: 'blur'}],
            },
            certificateTypeList: [ // 证件类型：1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它,11澳門身份證
                {label: '身份证', value: 1},
                {label: '护照', value: 2},
                {label: '港澳通行证', value: 3},
                {label: '回乡证', value: 4},
                {label: '台胞证', value: 5},
                {label: '军官证', value: 6},
                {label: '香港身份证', value: 7},
                {label: '台湾通行证', value: 8},
                {label: '户口本', value: 9},
                {label: '其它', value: 10},
                {label: '澳門身份證', value: 11},
            ],
            usertypeList: [
                {label: '成人', value: 1},
                {label: '儿童', value: 2},
                {label: '婴儿', value: 3},
            ],
            staffSexList: [
                {label: '男', value: 1},
                {label: '女', value: 2},
            ],
            nationalityList: []
        };
    },
    methods: {
        certificateTypeChange() {
            const __this = this;
            const form = __this.form;
            // __this.$nextTick(() => {
            //     __this.$refs.form.resetFields();
            // });
        },
        fromIdCard(val) { // 通过身份证获取生日
            const __this = this;
            let birthday = "";
            if (val != null && val != "") {
                if (val.length == 15) {
                    birthday = "19" + val.substr(6, 6);
                } else if (val.length == 18) {
                    birthday = val.substr(6, 8);
                }
                birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
            }
            __this.form.birthday = birthday;
        },
        init() {
            const __this = this;
            __this.id = __this.$route.query.id;
            __this.get_country();
            if (__this.id) {
                __this.get_traveller_detail();
            }
        },
        get_country() {
            const __this = this;
            request({levelType: 1}).then(res => {
                __this.nationalityList = res.datas;
            })
        },
        get_traveller_detail() {
            const __this = this;
            __this.loading = true;
            consumer_frequent_contacts_getList({ofcId: __this.id}).then(res => {
                __this.form = res.pageResult.pageData[0];
                __this.form.choice = 1;
                __this.form.certificateType = parseInt(__this.form.certificateType);
                __this.form.nationality = parseInt(__this.form.nationality);
                __this.form.staffSex = parseInt(__this.form.staffSex);
            }).finally(() => {
                __this.loading = false;
            })
        },
        add_traveller() {
            const __this = this;
            __this.form.passengerType = __this.form.certificateType === 6 ? 1 : __this.form.passengerType; // 证件类型为军官证默认为成人
            __this.$refs.form.validate((valid) => {
                if (valid) {
                    __this.loading = true;
                    consumer_frequent_contacts_addOrUpdate(__this.form).then(res => {
                        __this.$message({
                            message: '添加成功',
                            type: "success",
                            onClose: () => {
                                __this.$refs.form.resetFields();
                                __this.$router.push({
                                    name: 'admin-often-traveller'
                                })
                            }
                        })
                    }).finally(() => {
                        __this.loading = false;
                    })
                } else {
                    return false;
                }
            });
        },
        to_return() {
            const __this = this;
            __this.$router.push({
                name: 'admin-often-traveller'
            })
        }
    },
    created() {
        const __this = this;
    },
    activated() {
        const __this = this;
        __this.init();
    },
    updated() {
        const __this = this;

    },
    mounted() {

    },
    watch: {},
    filters: {},
};
